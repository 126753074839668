import {
  SaveWorkflowStepRequest,
  WorkflowResponse,
  WorkflowStepOperationType,
} from 'mapistry-shared';
import React from 'react';
import { EditFormulaStepModal } from './EditFormulaStepModal/EditFormulaStepModal';
import { EditJoinStepModal } from './EditJoinStepModal/EditJoinStepModal';

interface EditStepModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitStep: (step: SaveWorkflowStepRequest) => Promise<void>;
  organizationId: string;
  stepId?: string;
  stepType: WorkflowStepOperationType;
  workflow: WorkflowResponse;
}

export function EditStepModal(props: EditStepModalProps) {
  const { isOpen, stepType, stepId } = props;

  if (!isOpen) {
    return null;
  }

  const propsToPass = { ...props, isNewStep: !stepId };

  switch (stepType) {
    case WorkflowStepOperationType.FORMULA:
      return <EditFormulaStepModal {...propsToPass} />;
    case WorkflowStepOperationType.JOIN:
      return <EditJoinStepModal {...propsToPass} />;
    case WorkflowStepOperationType.AGGREGATION:
      throw new Error('todo@aggregation: aggregation steps not yet handled');
    default: {
      const exhaustiveCheck: never = stepType;
      throw new Error(
        `Trying to open the EditStepModal for an unknown step type ${exhaustiveCheck}`,
      );
    }
  }
}
