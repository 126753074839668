import FormulaIcon from '@svg/formula.svg';
import JoinIcon from '@svg/join.svg';
import {
  WorkflowResponse,
  WorkflowStepOperationType,
  WorkflowStepResponse,
} from 'mapistry-shared';
import React from 'react';

export function findStepById(workflow: WorkflowResponse, stepId?: string) {
  if (!stepId) return undefined;
  return workflow.steps.find((step) => step.id === stepId);
}

/** assuming that new steps will be added at the end, and that steps are ordered correctly */
export function findStepInputDataset(
  workflow: WorkflowResponse,
  stepId?: string,
) {
  // For existing steps, return the previous step's output dataset or the workflow's inputDataset.
  // For new steps, return the last step's outputDataset or the workflow's inputDataset.
  const stepIdx = stepId
    ? workflow.steps.findIndex((s) => s.id === stepId)
    : workflow.steps.length;

  return workflow.steps[stepIdx - 1]?.outputDataset || workflow.inputDataset;
}

type StepDisplayInfo = {
  title: string;
  icon: React.ReactElement;
};

export function getStepDisplayInfo(
  step: WorkflowStepResponse,
): StepDisplayInfo {
  const { type } = step;
  switch (type) {
    case WorkflowStepOperationType.FORMULA:
      return {
        icon: <FormulaIcon />,
        title: 'Formula',
      };
    case WorkflowStepOperationType.JOIN:
      return {
        icon: <JoinIcon />,
        title: 'Connect',
      };
    case WorkflowStepOperationType.AGGREGATION:
      throw new Error('todo@aggregation: aggregation steps not yet handled');
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(
        `Trying to render a Workflow step for an unknown step type ${exhaustiveCheck}`,
      );
    }
  }
}
